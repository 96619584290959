import * as styles from "./product-single-page.module.css";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Image,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { PageProps, graphql } from "gatsby";
import React, { useState } from "react";
import { TbHeadset, TbTruckReturn } from "react-icons/tb";

import AddToCartInput from "./AddToCartInput";
import { BsFillBagCheckFill } from "react-icons/bs";
import { FaRegClock } from "react-icons/fa6";
import { FaRegUserCircle } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { GoStack } from "react-icons/go";
import GridTable from "./GridTable/GridTable";
import { Helmet } from "react-helmet";
import { HorizontalProductCard } from "./Product/HorisontalProductCard";
import ImageZoom from "./Product/ImageZoom";
import Layout from "./Base/Layout";
import { MdOutlineEmail } from "react-icons/md";
import OneProductBundle from "./Product/OneProductBundle";
import { ProductBreadcrumb } from "./Shop/ProductBreadcrumbs";
import ProductBundles from "./Product/ProductBundles";
import ProductsFAQ from "./FAQ/productsFaq";
import { RelatedProductsCard } from "./Product/RelatedProductCard";
import { SEO } from "./Base/Seo";
import { brandsToExclude } from "../queries/toExclude";
import { fetchAllProducts } from "../queries/fetchAllProducts";
import mediumZoom from "medium-zoom";
import useAuth from "./useAuth";
import SingleProductPromoBanner from "./PromoBanner/SingleProductPromoBanner";

const Product: React.FC<ProductProps> = ({
  data,
  pageContext,
  location,
}: any) => {
  const {
    wpSimpleProduct: {
      name,
      databaseId,
      shortDescription,
      description,
      price,
      regularPrice,
      tierPrice,
      stockStatus,
    },
    wpSimpleProduct: { image },
    wpSimpleProduct: {
      galleryImages: { images },
    },
    wpSimpleProduct: {
      productCategories: { catName },
    },
    wpSimpleProduct: {
      productTags: { brandName },
    },
    wpSimpleProduct: {
      attributes: { attrName },
    },
    wpSimpleProduct: {
      related: { relatedProducts },
      upsell: { upsellProducts },
      crossSellProducts: { bundles },
    },
    wpSimpleProduct: {
      additionalProductInfo: { additionalProductInfoRepeater },
    },
  } = data;

  const { loggedIn, isUnlicensedUser, isCustomerDefault, user } = useAuth();
  let [qty, setQty] = useState(1);
  const [isLargerScreen] = useMediaQuery("(max-width: 768px)");

  const icons = [
    {
      component: <FaRegUserCircle size="24px" fill="#d28c49" />,
      name: "Worldwide Delivery",
    },
    {
      component: <FaRegClock size="24px" fill="#d28c49" />,
      name: "24/7 Ordering System",
    },
    {
      component: <TbHeadset size="24px" stroke="#d28c49" />,
      name: "Great Customer Service",
    },
    {
      component: <TbTruckReturn size="24px" stroke="#d28c49" />,
      name: "Flexible Return Policy",
    },
  ];
  const tierPricesData = [
    {
      amount: "1 - 5",
      price: tierPrice.tierPrice?.[0]?.price,
    },
    {
      amount: "6 - 10",
      price: tierPrice.tierPrice?.[0]?.price2,
    },
    {
      amount: "11 - 20",
      price: tierPrice.tierPrice?.[0]?.price3,
    },
    {
      amount: "21+",
      price: tierPrice.tierPrice?.[0]?.price4,
    },
  ];

  const [isOnSale] = useState(data.wpSimpleProduct.onSale);

  const categoryName = catName.map((item: any) => {
    return item.name;
  });
  const categorySlug = catName.map((item: any) => {
    return item.slug;
  });

  const allowedCategories =
    data.wpSimpleProduct.productCategories.catName.filter(
      (category: { name: string }) => {
        return (
          category.name !== "Bestselling Products" &&
          category.name !== "Related product"
        );
      }
    );
  const mainCategory = allowedCategories.find(
    (category: any) => !category.wpParent
  );
  const subCategories = allowedCategories.filter(
    (category: any) => category.wpParent
  );

  const isRestricted = brandName.every((item: any) =>
    brandsToExclude.includes(item.name)
  );

  let session: string | null = null;
  if (typeof window !== "undefined") {
    session = sessionStorage.getItem("shouldRedirect");
  }

  // Function for getting the image src
  const [currentImage, setCurrentImage] = useState(
    data.wpSimpleProduct.image?.sourceUrl
  );

  const getImageSrc = (e: any) => {
    setCurrentImage(e.target.src);
  };

  // zoom image
  const zoom = React.useRef(mediumZoom());

  const faqItems = data.wpSimpleProduct.faq.questionsrepeater;

  const combinedProducts = [...upsellProducts, ...relatedProducts];

  // Check if any product is restricted
  const hasRestrictedProducts = combinedProducts.some((item) =>
    brandsToExclude.includes(item.name)
  );

  // Product subsitute logic
  const products = fetchAllProducts();

  const suggestedProducts = data.wpSimpleProduct.productSubstitute?.substitute;

  const filteredSuggestedProducts = products?.filter((item: any) => {
    const mentionedProduct = suggestedProducts
      ?.slice(0, 4)
      .find((mp: any) => mp.productId == item.sku);

    if (mentionedProduct) {
      const isProductExcluded = item.productTags.brandName.every((i: any) =>
        brandsToExclude.includes(i.name)
      );

      if (
        (!loggedIn || isCustomerDefault || isUnlicensedUser) &&
        isProductExcluded
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  });

  //SEO
  const faqSchema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: faqItems
      ? faqItems.map(({ question, answer }: any) => ({
          "@type": "Question",
          name: question,
          acceptedAnswer: {
            "@type": "Answer",
            text: answer,
          },
        }))
      : [],
  };

  const productSchema = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: name,
    description: shortDescription,
    sku: databaseId,
    url: location.href,
    image: image.sourceUrl,
    offers: {
      "@type": "Offer",
      price: price ? parseFloat(price.replace("$", "")) : undefined,
      priceCurrency: "USD",
      availability: `https://schema.org/${stockStatus}`,
    },
    brand: {
      "@type": "Brand",
      name: brandName[0]?.name || "",
    },
  };

  const combinedSchema =
    faqSchema.mainEntity.length > 0
      ? [faqSchema, productSchema]
      : [productSchema];
  const combinedJsonLd = JSON.stringify(combinedSchema, null, 2);

  // Klaviyo view-product integration
  const trackViewedProduct = () => {
    //@ts-ignore
    if (typeof window !== "undefined" && window.klaviyo) {
      var item = {
        ProductName: name,
        ProductID: databaseId,
        Categories: catName.map((category: any) => category.name),
        ImageURL: image?.sourceUrl,
        Brand: brandName,
        Price: price,
      };
      //@ts-ignore
      window.klaviyo.push(["track", "Viewed Product", item]);
    } else {
      console.warn("Klaviyo is not initialized or loaded.");
    }
  };

  React.useEffect(() => {
    trackViewedProduct();
  }, []);

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">{combinedJsonLd}</script>
      </Helmet>
      <Box
        maxWidth={{ base: "100%", md: "1156px" }}
        margin="0px auto"
        maxW={{ base: "100%", md: "1156px" }}
        pt={{ base: "24px", md: "64px", lg: "48px" }}
        px={{ base: "16px", md: "16px", lg: "0px" }}
        pb="80px">
        <SingleProductPromoBanner />

        <HStack justifyContent="flex-start" pt="16px" pb="24px">
          <ProductBreadcrumb
            shop="Shop"
            category={mainCategory.name}
            categoryHref={mainCategory.slug}
            subcategory={subCategories.map((subcategory: { name: string }) => {
              return subcategory.name;
            })}
            subcategoryHref={subCategories.map(
              (subcategory: { slug: string }) => {
                return subcategory.slug;
              }
            )}
            product={name}
          />
        </HStack>
        <HStack
          flexDir={{ base: "column", xl: "row" }}
          alignItems="flex-start"
          gap="20px">
          <Box
            minW={{ base: "100%", md: "400px" }}
            w="100%"
            position="relative">
            {data.wpSimpleProduct.promotions?.onPromotion && (
              <Box
                position="absolute"
                zIndex="99"
                top="10px"
                left="8px"
                w="fit-content"
                bg="#F0FFF4"
                color="#39A26A"
                textTransform="uppercase"
                borderRadius="8px"
                px="8px">
                Low Quantity
              </Box>
            )}
            {data.wpSimpleProduct.promotions?.additionalTag && (
              <Box
                position="absolute"
                zIndex="99"
                top="40px"
                left="8px"
                w="fit-content"
                bg="#FC8181"
                color="#fff"
                textTransform="uppercase"
                borderRadius="8px"
                px="8px">
                {data.wpSimpleProduct.promotions?.additionalTag}
              </Box>
            )}
            <ImageZoom
              alt={data.wpSimpleProduct.image?.altText}
              src={currentImage}
              background="rgba(0, 0, 0, .6)"
              zoom={zoom.current}
              boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
              borderRadius="lg"
            />

            {images && images.length > 0 && (
              <HStack pt="4">
                {images.slice(0, 3).map((image: any) => (
                  <Image
                    position="relative"
                    fallback={<Skeleton />}
                    src={image.sourceUrl}
                    h="64px"
                    w="64px"
                    borderRadius="12px"
                    margin="0"
                    objectFit="contain"
                    onClick={getImageSrc}
                    cursor="pointer"
                    boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"
                  />
                ))}
              </HStack>
            )}
          </Box>
          <Box w={{ base: "100%", md: "auto" }}>
            <Text as="h1" fontSize="1.88rem!important" fontWeight="700">
              {name}
            </Text>
            {(!loggedIn || isUnlicensedUser || isCustomerDefault) &&
            isRestricted ? (
              <div role="button">
                <Link className="chakra-button css-1oloaee" href="/contact-us">
                  <Button
                    variant="solid"
                    colorScheme="#000"
                    p={"12px"}
                    ml="16px"
                    color="#000"
                    border="1px solid #120B0C"
                    borderRadius="full"
                    size="0.75rem"
                    width="full"
                    _hover={{ bg: "#120B0C", color: "#fff" }}>
                    Contact Us
                  </Button>
                </Link>
              </div>
            ) : (
              <>
                {isOnSale ? (
                  <>
                    <Text
                      color="#DF7C6C"
                      fontSize="16px"
                      fontWeight="400"
                      style={{ textDecoration: "line-through" }}>
                      {regularPrice}
                    </Text>
                    <Text color="gray.800" fontSize="24px" fontWeight="600">
                      {price}
                      <span className={styles.pricespan}>/unit</span>
                    </Text>
                  </>
                ) : (
                  <Text color="gray.800" fontSize="24px" fontWeight="600">
                    {" "}
                    {price} <span className={styles.pricespan}>/unit</span>
                  </Text>
                )}
                <HStack alignItems="center" gap="10px" pt="20px">
                  <HStack gap="10px">
                    <Text fontSize="14px" fontWeight="600">
                      Qty:
                    </Text>

                    <NumberInput
                      min={1}
                      defaultValue={1}
                      width="64px"
                      focusBorderColor="#d28c49"
                      onBlur={(e) => {
                        setQty(parseInt(e.currentTarget.value));
                      }}>
                      <NumberInputField height="36px" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </HStack>

                  {!loggedIn ? (
                    <Link className="chakra-button css-1oloaee" href="/sign-in">
                      <Button
                        bg="#000"
                        color="#fff"
                        border="1px solid black"
                        textTransform="uppercase"
                        height="40px"
                        fontSize="14px"
                        fontWeight="700"
                        borderRadius="48px"
                        _hover={{
                          color: "#000",
                          bg: "#fff",
                          border: "1px solid #120B0C",
                        }}>
                        SIGN IN TO ORDER
                      </Button>
                    </Link>
                  ) : (
                    <AddToCartInput
                      quantity={qty}
                      productId={databaseId}
                      name={name}
                      price={price}
                      isSuggested={false}
                      user={user}
                    />
                  )}
                </HStack>
                {/* TIER PRICE */}
                {data.wpSimpleProduct.promotions?.onPromotion ? (
                  <Text pt="16px" color="beige.500">
                    Promo and tiered pricing discounts cannot be combined.
                  </Text>
                ) : (
                  <GridTable data={tierPricesData} />
                )}

                {/* ADDITIONAL INFO */}
                <VStack alignItems="flex-start" gap="20px" py="20px">
                  <HStack>
                    <GoStack
                      width="20px !important"
                      height="20px"
                      strokeWidth="1px"
                    />
                    <Text fontWeight="700" fontSize="20px">
                      Product details
                    </Text>
                  </HStack>

                  <Box w="100%">
                    {attrName?.map((item: any, index: number) => {
                      const optionsString = item.options.toString();

                      return (
                        <HStack
                          key={`${index}${item.name}`}
                          flexFlow={{ base: "column", md: "row" }}
                          alignItems={{ base: "flex-start", md: "center" }}
                          gap={{ base: "0px", md: "8px" }}
                          pb={{ base: "8px", md: "0px" }}>
                          <Text
                            fontWeight="600"
                            minW={{ base: "170px", md: "200px" }}>
                            {item.name}
                          </Text>
                          {item.name === "Brand:" ? (
                            <div className={styles.belotero}>
                              {brandName?.map((brandItem: any) => {
                                if (brandItem.name === optionsString) {
                                  return (
                                    <Link
                                      href={`/shop/brand/${brandItem.slug}`}
                                      key={brandItem.slug}>
                                      {optionsString}
                                    </Link>
                                  );
                                }
                              })}
                            </div>
                          ) : (
                            <Box>{item.options}</Box>
                          )}
                        </HStack>
                      );
                    })}
                  </Box>

                  <Box>
                    <Text fontWeight="600" fontSize="16px">
                      Product description
                    </Text>
                    <Text
                      fontSize="16px"
                      dangerouslySetInnerHTML={{ __html: shortDescription }}
                    />
                  </Box>
                </VStack>

                {/* PRODUCT BUNDLES */}

                {bundles && (
                  <VStack w="100%" alignItems="flex-start">
                    <HStack alignItems="center">
                      <GoStack
                        width="20px !important"
                        height="20px"
                        strokeWidth="1px"
                      />
                      <Text color="gray.800" fontSize="16px" fontWeight="600">
                        Best bundled with
                      </Text>
                    </HStack>
                    <VStack w="100%">
                      {bundles?.length == 1 ? (
                        <>
                          <OneProductBundle
                            crossSellProducts={bundles}
                            products={products}
                            mainProductId={databaseId}
                            mainProductPrice={price}
                          />
                        </>
                      ) : (
                        <>
                          <ProductBundles
                            crossSellProducts={bundles}
                            products={products}
                            mainProductId={databaseId}
                            mainProductPrice={price}
                            showProductImages={true}
                          />
                        </>
                      )}
                    </VStack>
                  </VStack>
                )}
              </>
            )}

            <SimpleGrid columns={{ base: 1, md: 2 }}>
              {icons.map((item) => {
                return (
                  <HStack>
                    {item.component}
                    <Box bg="gray.50" h="40px" w="1px"></Box>
                    <Text fontSize="16px" fontWeight="600">
                      {item.name}
                    </Text>
                  </HStack>
                );
              })}
            </SimpleGrid>
          </Box>
        </HStack>

        <Box
          bg="#FFF9F4"
          borderTopLeftRadius="xl"
          borderTopRightRadius="xl"
          p="16px"
          mt="24px">
          <VStack alignItems="flex-start" maxW="642px">
            <HStack
              flexDirection={{ base: "column", md: "row" }}
              alignItems="flex-start"
              gap="8px">
              <BsFillBagCheckFill size="20px" fill="#D28C49" />
              <Text fontSize="20px" fontWeight="700">
                Shop with Confidence:{" "}
                <Text as="span" color="beige.500">
                  {" "}
                  Product Authenticity{" "}
                </Text>{" "}
                is Guaranteed
              </Text>
            </HStack>
            <Text>
              All products available at Doctor Medica shop are obtained from
              respective manufacturers and contain original <b>LOT numbers</b>.
              Contact us if you have any questions about product LOT numbers.
            </Text>
          </VStack>
        </Box>
        <HStack
          bg="gray.900"
          px="16px"
          py="8px"
          color="#fff"
          borderBottomLeftRadius="xl"
          borderBottomRightRadius="xl">
          <HStack></HStack>
          <HStack
            flexDirection={{ base: "column", md: "row" }}
            alignItems="flex-start"
            gap="12px">
            <HStack>
              <FiPhone stroke="#D28C49" size="24px" />
              <Box bg="beige.300" h="40px" w="1px"></Box>
              <HStack fontSize="16px">
                <Link
                  href="tel:+1-866-343-2413"
                  fontSize="16px"
                  fontWeight="400"
                  _hover={{
                    color: "#D28C49",
                    textDecoration: "underline!important",
                  }}>
                  +1-866-343-2413,
                </Link>

                <Link
                  href="tel:+1-866-485-6820"
                  fontSize="16px"
                  fontWeight="400"
                  _hover={{
                    color: "#D28C49",
                    textDecoration: "underline!important",
                  }}>
                  +1-866-485-6820
                </Link>
              </HStack>
            </HStack>

            <Link
              href="mailto:info@doctormedica.co"
              _hover={{
                color: "#D28C49",
                textDecoration: "underline!important",
              }}>
              <HStack>
                <MdOutlineEmail fill="#D28C49" size="24px" />

                <Box bg="beige.300" h="40px" w="1px"></Box>
                <Text fontSize="16px" fontWeight="400">
                  info@doctormedica.co
                </Text>
              </HStack>
            </Link>
          </HStack>
        </HStack>

        {/* ADDITIONAL INFO */}
        <Box
          id="additonalInfo"
          as="section"
          background="#FCFCFC"
          display={
            additionalProductInfoRepeater?.length > 0 ? "block" : "none"
          }>
          {!isLargerScreen ? (
            <>
              {" "}
              <Tabs isFitted variant="enclosed">
                <TabList mb="1em" borderColor="#D7DDD3">
                  {additionalProductInfoRepeater?.map(
                    (item: { name: string }) => {
                      return (
                        <Tab
                          fontSize="14px"
                          p="10px 16px"
                          color="#737373"
                          _selected={{
                            fontWeight: "600",
                            color: "#120B0C",
                            border: "1px solid #D7DDD3",
                            marginBottom: "-1px",
                            borderTopLeftRadius: "12px",
                            borderTopRightRadius: "12px",
                            borderBottomColor: "#FCFCFC",
                          }}>
                          {item.name}
                        </Tab>
                      );
                    }
                  )}
                </TabList>
                <TabPanels>
                  {additionalProductInfoRepeater?.map(
                    (item: { description: string }) => {
                      return (
                        <TabPanel>
                          <Text
                            fontSize="14px"
                            color="#595959"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}></Text>
                        </TabPanel>
                      );
                    }
                  )}
                </TabPanels>
              </Tabs>
            </>
          ) : (
            <>
              <Accordion defaultIndex={0} allowToggle>
                {additionalProductInfoRepeater?.map(
                  (item: { name: string; description: string }) => {
                    return (
                      <AccordionItem p="16px 4px" borderColor="#D7DDD3">
                        <h2>
                          <AccordionButton
                            color="#737373"
                            _hover={{
                              bg: "transparent",
                            }}
                            _focus={{
                              bg: "transparent",
                            }}
                            _expanded={{
                              color: "gray.900",
                              fontWeight: "600",
                              bg: "transparent",
                            }}>
                            <Box as="span" flex="1" textAlign="left">
                              {item.name}
                            </Box>
                            <AccordionIcon color="#D28C49" />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <Text
                            fontSize="14px"
                            color="#595959"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}></Text>
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  }
                )}
              </Accordion>
            </>
          )}
        </Box>

        {/* ALTERNATIVE PRODUCTS SECTION */}
        {filteredSuggestedProducts.length > 0 ? (
          <Box id="alternativeProducts" as="section" pt="20px">
            <Box
              bgColor="#FBF5F0"
              p="16px"
              w="100%"
              borderRadius="xl"
              h={{ base: "100%", md: "100%" }}>
              <HStack
                flexDirection={{ base: "column", lg: "row" }}
                gap={{ base: "40px", lg: "0px", xl: "16px", "2xl": "0px" }}
                h="100%"
                justifyContent="space-between"
                alignItems="flex-start">
                <VStack
                  w={{ base: "100%", lg: "500px" }}
                  alignItems="flex-start"
                  gap="20px">
                  <Box h="2px" w="24px" bg="beige.500"></Box>
                  <Box>
                    <Text fontSize="20px" lineHeight="20px" fontWeight="700">
                      Alternative Products
                    </Text>
                    <Text fontSize="16px">
                      Check out these alternatives that might suit your needs
                    </Text>
                  </Box>
                  <Link href={`/category/${categorySlug[0]}`} w="100%">
                    <Button
                      variant="outline"
                      h="45px"
                      w={{ base: "100%", md: "auto" }}
                      padding="12px 20px"
                      fontWeight="700"
                      border="0.4px solid #120B0C"
                      borderRadius="48px"
                      fontSize={{ base: "10px", md: "14px" }}
                      textTransform="uppercase"
                      _hover={{ color: "#fff", bg: "#120B0C" }}>
                      View more alternatives from {categoryName[0]}
                    </Button>
                  </Link>
                </VStack>
                <SimpleGrid
                  width={{ base: "100%", md: "auto" }}
                  columns={{
                    base: 1,
                    sm: 1,
                    xl: filteredSuggestedProducts.length === 1 ? 1 : 2,
                    "2xl": filteredSuggestedProducts.length === 1 ? 1 : 2,
                  }}
                  gap="8px">
                  {filteredSuggestedProducts.map((item: any) => (
                    <HorizontalProductCard
                      key={item.databaseId}
                      props={item}
                      outlet={false}
                      margin={".3rem"}
                      width={"236px"}
                      isProductBundles={false}
                    />
                  ))}
                </SimpleGrid>
              </HStack>
            </Box>
          </Box>
        ) : null}

        <Box
          pt="20px"
          display={
            data.wpSimpleProduct.faq.questionsrepeater == null
              ? "none"
              : "block"
          }>
          <Box pb="24px">
            <Box className={styles.divider} />
            <Text pt="14px" fontSize="20px" fontWeight="700">
              FAQ
            </Text>
          </Box>
          <ProductsFAQ props={data.wpSimpleProduct.faq} />
        </Box>

        {((!loggedIn || isCustomerDefault || isUnlicensedUser) &&
          hasRestrictedProducts) ||
        bundles?.length > 0 ? (
          <></>
        ) : (
          <Box pt="24px">
            <Box>
              <Box className={styles.divider} />
              <Text pt="14px" fontSize="20px" fontWeight="700">
                Products you may also like
              </Text>
            </Box>

            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 3, lg: 5 }}
              gap="8px"
              pt="48px">
              {[...upsellProducts, ...relatedProducts]
                .filter((item: any) => {
                  return !(
                    (!loggedIn || isCustomerDefault || isUnlicensedUser) &&
                    item.productTags?.nodes?.some(
                      (tag: any) => tag.name === "Pharmaceuticals"
                    )
                  );
                })
                .slice(0, 5)
                .map((product: any) => (
                  <RelatedProductsCard
                    props={product}
                    upsellData={upsellProducts}
                    relatedData={relatedProducts}
                  />
                ))}
            </SimpleGrid>

            <HStack justifyContent="center" pt="24px">
              <Link
                href="/shop"
                _hover={{ textDecoration: "none", color: "#fff!important" }}>
                <Button
                  variant="outline"
                  fontWeight="700"
                  w={{ base: "100%", md: "207px" }}>
                  VIEW ALL PRODUCTS
                </Button>
              </Link>
            </HStack>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

enum WpStockStatusEnum {
  IN_STOCK = "IN_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  ON_BACKORDER = "ON_BACKORDER",
}

type ProductProps = PageProps<ProductQuery>;

type ProductQuery = {
  wpSimpleProduct: {
    slug: string;
    name: string;
    databaseId: number;
    shortDescription: string;
    description: string;
    price: string;
    regularPrice: string;
    onSale?: boolean;
    stockStatus: WpStockStatusEnum;
    attributes: {
      attrName: [];
    };
    productSubstitute: {
      substitue: {
        productId: number;
      };
    };

    image?: {
      sourceUrl?: string;
      altText?: string;
    };
    galleryImages: {
      images: [];
    };
    productCategories: {
      catName: [];
    };
    productTags: {
      brandName: [];
    };
    tierPrice: any;
    related: {
      relatedProducts: [];
    };
    upsell: { upsellProducts: [] };
    crossSellProducts: { bundles: [] };
    additionalProductInfo?: { additionalProductInfoRepeater: [] };
    faq: {
      questionsrepeater: [
        {
          question: string;
          answer: string;
        }
      ];
    };
  };
  quantity: number;
  key: string;
  onChangeQuantity: (qty: number) => void;
};

type HeadProps = {
  wpSimpleProduct: {
    seo?: {
      title?: string;
      metaDesc?: string;
    };
  };
};

export const query = graphql`
  query PRODUCT($slug: String!) {
    wpSimpleProduct(slug: { eq: $slug }) {
      tierPrice {
        tierPrice {
          price
          price2
          price3
          price4
        }
      }
      seo {
        title
        metaDesc
        metaKeywords
        schema {
          articleType
          pageType
          raw
        }
        breadcrumbs {
          text
          url
        }
      }

      crossSellProducts {
        bundles {
          bundleName
          bundleProducts {
            productSku
          }
        }
      }

      additionalProductInfo {
        additionalProductInfoRepeater {
          name
          description
        }
      }

      related {
        relatedProducts: nodes {
          name
          slug
          databaseId
          image {
            sourceUrl
            altText
          }
          promotions {
            onPromotion
            additionalTag
          }
          productTags {
            nodes {
              name
            }
          }
          ... on WpSimpleProduct {
            id
            name
            price
            regularPrice
            onSale
            seo {
              title
              metaDesc
            }
            productTags {
              nodes {
                name
              }
            }
          }
          upsell {
            upsellProducts: nodes {
              name
              slug
              databaseId
              description
              image {
                sourceUrl
                altText
              }
              productTags {
                nodes {
                  name
                }
              }
              ... on WpSimpleProduct {
                id
                name
                price
                regularPrice
                onSale
                stockStatus
              }
            }
          }
        }
      }
      upsell {
        upsellProducts: nodes {
          name
          slug
          databaseId
          description
          image {
            sourceUrl
            altText
          }
          productTags {
            nodes {
              name
            }
          }
          ... on WpSimpleProduct {
            id
            name
            price
            regularPrice
            onSale
            stockStatus
          }
        }
      }

      galleryImages {
        images: nodes {
          sourceUrl
          altText
        }
      }

      name
      slug
      databaseId
      sku
      shortDescription
      description
      stockStatus

      productSubstitute {
        substitute {
          productId
        }
      }

      image {
        sourceUrl
        altText
      }
      promotions {
        onPromotion
        additionalTag
      }
      productCategories {
        catName: nodes {
          name
          slug
          wpParent {
            node {
              id
              name
              slug
            }
          }
        }
      }
      productTags {
        brandName: nodes {
          name
          slug
        }
      }
      attributes {
        attrName: nodes {
          name
          options
        }
      }
      tierPrice {
        tierPrice {
          price
          price2
          price3
          price4
        }
      }
      ... on WpSimpleProduct {
        id
        name
        price
        regularPrice
        onSale
        salePrice
      }
      faq {
        questionsrepeater {
          question
          answer
        }
      }
    }
  }
`;

export default Product;

export const Head: React.FC<HeadProps> = ({ data }: any) => {
  const wpSimpleProduct: {
    seo: { title: any; metaDesc: any };
  } = data;
  return (
    <SEO
      title={data.wpSimpleProduct.seo?.title}
      description={data.wpSimpleProduct.seo?.metaDesc}
    />
  );
};
