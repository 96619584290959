import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import AddToCartBundle from "../AddToCartBundle";
import { HorizontalProductCard } from "./HorisontalProductCard";
import { brandsToExclude, categoriesToExclude } from "../../queries/toExclude";
import useAuth from "../useAuth";

const ProductBundles = ({
  crossSellProducts,
  products,
  mainProductId,
  mainProductPrice,
  showProductImages,
  isCart,
}: any) => {
  const { loggedIn, isUnlicensedUser, isCustomerDefault, user } = useAuth();
  const constantId = mainProductId;
  const getProductById = (productSku: string) =>
    products?.find((p: { sku: string }) => p.sku === productSku);

  const getTotalPrice = (bundleProducts: any) => {
    const mainPriceStr = mainProductPrice
      ? String(mainProductPrice).replace(/[^0-9.-]+/g, "")
      : "0";
    const numericMainPrice = parseFloat(mainPriceStr);

    return bundleProducts.reduce(
      (total: number, product: { productSku: string }) => {
        const matchingProduct = getProductById(product.productSku);
        const isProductExcluded = brandsToExclude.some((brand) =>
          matchingProduct?.name.includes(brand)
        );

        if (
          matchingProduct &&
          typeof matchingProduct.price === "string" &&
          (!isProductExcluded ||
            (loggedIn && !isCustomerDefault && !isUnlicensedUser))
        ) {
          const numericPriceStr = matchingProduct.price.replace(
            /[^0-9.-]+/g,
            ""
          );
          const numericPrice = parseFloat(numericPriceStr);

          if (!isNaN(numericPrice)) {
            return total + numericPrice;
          }
        }
        return total;
      },
      numericMainPrice
    );
  };

  const ProductImages = ({ bundleProducts }: any) => {
    const { loggedIn, isUnlicensedUser, isCustomerDefault } = useAuth();
    return (
      <HStack gap="4px">
        {bundleProducts.map((product: { productSku: string }) => {
          const matchingProduct = getProductById(product.productSku);
          const isProductExcluded = brandsToExclude.some((brand) =>
            matchingProduct?.name.includes(brand)
          );

          if (
            matchingProduct &&
            (!isProductExcluded ||
              (loggedIn && !isCustomerDefault && !isUnlicensedUser))
          ) {
            return (
              <Image
                key={product.productSku}
                w="40px"
                h="40px"
                borderRadius="8px"
                border="1px solid #DCA672"
                src={matchingProduct.image?.sourceUrl}
                alt={matchingProduct.image?.altText}
              />
            );
          }
          return null;
        })}
      </HStack>
    );
  };

  const ProductNames = ({ bundleProducts }: any) => {
    const { loggedIn, isUnlicensedUser, isCustomerDefault } = useAuth();
    const visibleProductNames = bundleProducts
      .map((product: { productSku: string }) => {
        const matchingProduct = getProductById(product.productSku);
        if (matchingProduct) {
          const isProductExcluded = brandsToExclude.some((brand) =>
            matchingProduct.name.includes(brand)
          );

          if (
            isProductExcluded &&
            (!loggedIn || isCustomerDefault || isUnlicensedUser)
          ) {
            return null;
          }
          return matchingProduct.name;
        }
        return null;
      })
      .filter((name: string | null) => name !== null);

    return (
      <Text fontSize="12px" textAlign="left">
        {visibleProductNames.join(", ")}
      </Text>
    );
  };

  const ProductIds = ({ bundleProducts }: any, constantId: number) => {
    const ids = bundleProducts
      .map((product: { productSku: string }) => {
        const matchingProduct = getProductById(product.productSku);
        const isProductExcluded = brandsToExclude.some((brand) =>
          matchingProduct?.name.includes(brand)
        );

        if (
          matchingProduct &&
          (!isProductExcluded ||
            (loggedIn && !isCustomerDefault && !isUnlicensedUser))
        ) {
          return matchingProduct.databaseId;
        }
        return null;
      })
      .filter((id: number | null) => id !== null);

    return isCart ? ids : [constantId, ...ids];
  };

  const isBundleRestricted = (bundleProducts: any) => {
    return bundleProducts.every((product: { productSku: string }) => {
      const matchingProduct = getProductById(product.productSku);
      return matchingProduct
        ? matchingProduct.productTags?.brandName.every((i: any) =>
            brandsToExclude.includes(i.name)
          )
        : false;
    });
  };

  return crossSellProducts.map((bundle: any, index: any) => {
    const totalPrice = useMemo(
      () => getTotalPrice(bundle.bundleProducts),
      [bundle.bundleProducts]
    );

    const productIds = ProductIds(
      { bundleProducts: bundle.bundleProducts },
      constantId
    );

    if (
      isBundleRestricted(bundle.bundleProducts) &&
      (!loggedIn || isCustomerDefault || isUnlicensedUser)
    ) {
      return null;
    }
    const cartName =
      bundle.bundleProducts.length > 1 ? "Add this bundle" : "Add to cart";

    return (
      <Accordion
        allowMultiple
        w="100%"
        defaultIndex={index === 0 ? [0] : undefined}
        key={bundle.bundleName}>
        <AccordionItem border="none" pb="8px">
          <AccordionButton
            bg="#F1D8C1"
            borderRadius="12px"
            border="none"
            p="10px"
            justifyContent="space-between"
            _hover={{ bg: "#F1D8C1" }}>
            <HStack w="100%">
              {showProductImages && (
                <ProductImages bundleProducts={bundle.bundleProducts} />
              )}
              <VStack w="66%" gap="4px" alignItems="flex-start">
                <Text
                  color="gray.800"
                  fontSize="14px"
                  fontWeight="700"
                  textTransform="uppercase">
                  {bundle.bundleName}
                </Text>
                <ProductNames bundleProducts={bundle.bundleProducts} />
              </VStack>
            </HStack>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel bg="#FBF5F0" borderRadius="16px" mt="8px">
            <Stack
              flexDirection={{ base: "column", sm: "row" }}
              justifyContent="space-between"
              w="100%">
              <Stack flexFlow={{ base: "column", md: isCart }} w="auto">
                {bundle.bundleProducts.map((product: any) => {
                  const matchingProduct = getProductById(product.productSku);

                  if (matchingProduct) {
                    const isProductExcluded =
                      matchingProduct.productTags?.brandName.every((i: any) =>
                        brandsToExclude.includes(i.name)
                      );

                    if (
                      isProductExcluded &&
                      (!loggedIn || isCustomerDefault || isUnlicensedUser)
                    ) {
                      return null;
                    } else {
                      return (
                        <HorizontalProductCard
                          key={product.productSku}
                          props={matchingProduct}
                          outlet={false}
                          margin=".3rem"
                          width="236px"
                          isProductBundles={true}
                        />
                      );
                    }
                  }
                  return null;
                })}
              </Stack>
              <VStack justifyContent="center">
                <Text>${totalPrice} total</Text>

                <AddToCartBundle
                  productId={productIds}
                  name={bundle.bundleName}
                  price={totalPrice.toFixed(2)}
                  cartName={cartName}
                  user={user}
                />
              </VStack>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  });
};

export default ProductBundles;
